import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";

import Home from "./Home";

const App = () => (
  <ThemeProvider>
    <CSSReset />
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  </ThemeProvider>
);

export default App;
