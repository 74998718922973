import React from "react";
import {
  Stack,
  Image,
  Box,
  IconButton,
  Heading,
  Link,
  Text,
} from "@chakra-ui/core";
import { FaLinkedin, FaGithub, FaStrava } from "react-icons/fa";
import { IconContext } from "react-icons";

const links = [
  {
    icon: FaLinkedin,
    color: "#2867B2",
    url: "https://www.linkedin.com/in/yonistap/",
  },
  {
    icon: FaGithub,
    color: "#24292e",
    url: "https://github.com/ynrstp",
  },
  {
    icon: FaStrava,
    color: "#FC6100",
    url: "https://www.strava.com/athletes/24716283",
  },
];

class Home extends React.Component {
  render() {
    return (
      <Box d="flex" alignItems="center" flexDirection="column" height="100%">
        <Box d="flex" flexGrow={1} flexShrink={0} flexBasis="auto">
          <Stack mt={20} spacing={6} align="center">
            <Stack spacing={2} textAlign="center" direction="column">
              <Heading as="h1" size="md">
                Yoni Stap
              </Heading>
              <Heading as="h3" size="xs" fontWeight="semibold">
                <Text lineHeight="1.5rem">
                  <strong>Currently:</strong>{" "}
                </Text>
                Information Security Officer @ {""}
                {/* <Link
                  color="#F1006B"
                  isExternal
                  href="https://www.jouwomgeving.nl/"
                >
                  Jouw Omgeving B.V.
                </Link> */}
                <Link
                  color="#1191fa"
                  isExternal
                  href="https://www.umcutrecht.nl/en"
                >
                  University Medical Center Utrecht (UMCU)
                </Link>
              </Heading>
              <Heading as="h3" size="xs" fontWeight="semibold">
                <Text lineHeight="1.5rem">
                  <strong>Next event:</strong>{" "}
                </Text>
                <Link
                  color="#FC6100"
                  isExternal
                  href="https://www.nnmarathonrotterdam.nl/"
                >
                  Rotterdam Marathon 2025
                </Link>
              </Heading>
            </Stack>
            <Stack isInline spacing={6} align="center">
              {links.map(({ color, icon, url }, key) => (
                <Box key={key}>
                  <IconContext.Provider value={{ color }}>
                    <IconButton
                      variant="outline"
                      variantColor="gray"
                      aria-label="Open LinkedIn"
                      fontSize="16px"
                      isRound
                      icon={icon}
                      onClick={() => window.open(url, "_blank")}
                    />
                  </IconContext.Provider>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
        <Box
          d="flex"
          justifyContent="center"
          flexShrink={0}
          p={4}
          px={100}
          borderTop="1px"
          borderColor="gray.200"
        >
          <Text color="gray.800" fontSize="xs">
            Made by Yoni Stap © 2024 - yonistap.com
          </Text>
        </Box>
      </Box>
    );
  }
}

export default Home;
